import { useState } from 'react';
import { ImportOfferSteps } from './ImportOfferSteps';
import { alCaponeService } from './alCaponeService';
import { useUploadFile } from './useUploadFile';

export const useImportOffers = () => {
  const { uploadFile, progress, loading: loadingUploadFile } = useUploadFile();
  const [step, setStep] = useState(ImportOfferSteps.IDLE);
  const [error, setError] = useState<null | Error>(null);
  const importOffers = async (file: File) => {
    setError(null);
    try {
      setStep(ImportOfferSteps.UPLOADING_FILE);
      const { filename } = await uploadFile(file);
      setStep(ImportOfferSteps.IMPORTING_OFFERS);
      await alCaponeService.importOffers(filename);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      throw err;
    } finally {
      setStep(ImportOfferSteps.IDLE);
    }
  };
  return {
    loading: loadingUploadFile || step === ImportOfferSteps.IMPORTING_OFFERS,
    importOffers,
    progress,
    step,
    error,
  };
};
